<template>
  <div>
    <div class="content">
      Welcome to GiftlyAI, your go-to AI gift finder, offering cool gift ideas at the click of a button. Discover the ease of finding gifts with our AI-powered platform – no sign-ups, no fees, just a plethora of AI gift ideas ready to inspire you.

      <h2>Effortlessly Discover Unique and Personalized Gifts</h2>
      GiftlyAI, the premier tool in AI gift suggestions, transforms the way you find the perfect gift for any individual and occasion. With advanced AI gift recommendations, GiftlyAI offers you a range of cool gift ideas, tailored to match the personal tastes and interests of your recipients.

      <h2>How to Use GiftlyAI</h2>
      <ul>
        <li>Gender Selection: Utilize our AI gift finder to select gifts tailored to the recipient's gender, adding a layer of relevancy and personalization.</li>
        <li>Age Specification: Our AI gift idea generator considers age to ensure age-appropriate recommendations, ideal for gift seekers of any demographic.</li>
        <li>Interests Input: Specify up to three interests, and GiftlyAI will curate a list of potential gifts that resonate with the recipient's unique interests and passions.</li>
        <li>Budget Choice: Set your budget in our user-friendly interface, whether seeking gifts under $50 ($), between $50-150 ($$), or over $150 ($$$). GiftlyAI is your go-to tool for personalized gift ideas.</li>
        <li>Discovery: Click 'Find' and let our AI gift generator unveil a selection of gifts from Amazon, each with a secure link for a trusted and effortless shopping experience.</li>
      </ul>

      <h2>Seamless Discovery and Trusted Shopping</h2>
      Once you input your criteria and press 'Find', GiftlyAI promptly offers a range of AI gift ideas tailored to your needs. Each carefully selected option includes a direct link to Amazon, ensuring a trusted and smooth shopping experience. Our use of affiliate links keeps GiftlyAI free for all users, offering unparalleled value without any cost, while upholding the highest standards of security and user trust.

      <h2>Explore Even More Gift Ideas</h2>
      Haven't found the perfect gift yet? Click the 'View More' button for an expanded selection of AI gift ideas. Continue your journey through an enriched collection of options, ensuring you find precisely what you're searching for. With GiftlyAI, the journey to the perfect gift is limitless and always within reach.

      <h2>Benefits of Using GiftlyAI: Your Go-To AI Gift Tool</h2>
      <ul>
        <li>Diverse Selection: From present finders for the holiday season to creative AI-generated gift ideas, our AI covers all bases.</li>
        <li>User Experience: Navigate our platform with unparalleled ease – we're the intuitive and cool gift ideas AI generator you've always wanted.</li>
        <li>Cost-Efficient: Absolutely free to use, GiftlyAI is the AI gift assistant that values both your gifting experience and your budget.</li>
      </ul>
      <h2>Your Perfect Gift Awaits with GiftlyAI</h2>
      <p>
        At GiftlyAI, we're dedicated to making your gift-giving experience easy and enjoyable. Our AI-driven platform is more than just a gift finder; it's about discovering the perfect match for your loved ones, tailored to their interests and your budget.
      </p>
      <p>
        With GiftlyAI, you're not just giving a gift – you're giving a personal, thoughtful experience. Start your journey to effortless and memorable gifting today – because the best gifts come with a personal touch.
      </p>
      <p><strong>Try GiftlyAI now – where technology meets thoughtful gifting.</strong></p>
      <p>
        Love GiftlyAI? Let your friends and family know about it! Use our convenient social media sharing buttons below to spread the word and help others discover the joy of easy, personalized gifting. A simple click can make a big difference in someone's gift-giving experience!
      </p>

    </div>
    <div class="social-share-buttons background">
      <a href="https://www.facebook.com/sharer/sharer.php?u=https://giftly.ai/" class="social-share-button facebook" target="_blank">Facebook</a>
      <a href="https://twitter.com/intent/tweet?url=https://giftly.ai/&text=Check%20out%20this%20amazing%20AI-powered%20gift%20finder!" class="social-share-button twitter" target="_blank">Twitter</a>
      <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://giftly.ai/&title=Find%20the%20Perfect%20Gift%20with%20Giftly.ai&summary=Discover%20personalized%20gift%20ideas%20instantly%20using%20AI.&source=LinkedIn" class="social-share-button linkedin" target="_blank">LinkedIn</a>
      <a href="https://wa.me/?text=Check%20out%20Giftly.ai,%20an%20amazing%20AI-powered%20gift%20finder:%20https://giftly.ai/" class="social-share-button whatsapp" target="_blank">WhatsApp</a>
    </div>
  </div>
</template>
<style>
/* Container for the social share buttons */
.social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px; /* Adds 20px margin around the div */
  padding: 20px; /* Optional: Adds space inside the div, around the buttons */
}

/* Base style for all buttons */
.social-share-button {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Facebook Button Style */
.social-share-button.facebook {
  background-color: #3b5998;
}

.social-share-button.facebook:hover {
  background-color: #2d4373;
}

/* Twitter Button Style */
.social-share-button.twitter {
  background-color: #1da1f2;
}

.social-share-button.twitter:hover {
  background-color: #0d95e8;
}

/* LinkedIn Button Style */
.social-share-button.linkedin {
  background-color: #0077b5;
}

.social-share-button.linkedin:hover {
  background-color: #005582;
}

/* WhatsApp Button Style */
.social-share-button.whatsapp {
  background-color: #25d366;
}

.social-share-button.whatsapp:hover {
  background-color: #1da851;
}

/*.content {*/
/*  color: white; !* This sets the text color to white for better readability *!*/
/*  padding: 20px; !* Optional: Adds space inside the div *!*/
/*  margin: 20px; !* Optional: Adds space outside the div *!*/
/*  border-radius: 10px; !* Optional: Adds rounded corners to the div *!*/
/*}*/

.content {
  background-color: whitesmoke;
  color: #222222;
  padding: 20px;
  margin: 20px auto; /* Centers the content div and adds margin around it */
  border-radius: 10px;
  max-width: 800px; /* Sets a maximum width for larger screens */
  box-sizing: border-box; /* Include padding and border in the width and height */
  text-align: left;
}

/* Responsive media query for smaller screens */
@media (max-width: 768px) {
  .content {
    margin: 10px; /* Smaller margin on small screens */
    padding: 10px; /* Smaller padding on small screens */
  }
}

/* Base styles */
.social-share-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows buttons to wrap onto the next line on small screens */
  gap: 10px;
  margin: 20px;
}

.social-share-button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1; /* Allows buttons to grow and fill the space */
  min-width: 120px; /* Minimum width for buttons */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Specific styles */
/* ... (Include other specific styles for each social media button here) ... */

/* Responsive media query */
@media (max-width: 768px) {
  .social-share-buttons {
    flex-direction: column; /* Stack buttons vertically on small screens */
  }

  .social-share-button {
    text-align: center;
    width: 100%; /* Full width buttons on small screens */
    box-sizing: border-box; /* Ensures padding is included in width */
  }
}

h2 {
  margin-top: 20px; /* Adds space above the heading */
  margin-bottom: 10px; /* Adds space below the heading */
  font-size: 24px; /* Adjust font size as needed for mobile */
}

/* Add some space between paragraphs */
p {
  margin-top: 10px; /* Adds space above the paragraph */
  margin-bottom: 10px; /* Adds space below the paragraph */
  font-size: 16px; /* Adjust font size as needed for mobile */
}

/* Adjust the list spacing */
ol {
  margin-top: 10px; /* Adds space above the list */
  margin-bottom: 10px; /* Adds space below the list */
}

/* Adjust spacing for list items */
li {
  margin-bottom: 8px; /* Adds space below each list item */
}

/* Ensure that the content div is not too wide on mobile */
.content {
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content {
    padding: 15px; /* Slightly smaller padding on smaller screens */
    margin: 10px;
  }
  h2 {
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 22px; /* Slightly smaller font size on smaller screens */
  }
  p, ol, li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
</style>